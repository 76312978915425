// Convert React Select Options
export const convertToSelectOptions = (data) => {
  const Options = [];
  data.sort().map((item, index) => {
    Options.push({
      name: item,
      label: item,
      id: `${item}-${index}`,
    });
  });
  return Options;
};

export const capitalizeString = (str) => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  else return "";
};

export const convertToLabel = (str) => {
  return capitalizeString(str ? str.replaceAll("_", " ") : "");
};

//  Convert time to Duration
export function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " yrs";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hrs";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " mins";
  }
  return Math.floor(seconds) + " secs";
}

// Convert number to thousand separated string
// eg: 100000 -> 100,000
export function thousands_separators(num) {
  if (num) {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  }
  return num;
}

/**
 * Password validator for login pages
 */

// has number
const hasNumber = (value) => {
  return new RegExp(/[0-9]/).test(value);
};

// has mix of small and capitals
const hasMixed = (value) => {
  return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value);
};

// has special chars
const hasSpecial = (value) => {
  return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
};

// set color based on password strength
export const strengthColor = (count) => {
  if (count < 2) return { label: "Poor", color: value.errorMain };
  if (count < 3) return { label: "Weak", color: value.warningDark };
  if (count < 4) return { label: "Normal", color: value.orangeMain };
  if (count < 5) return { label: "Good", color: value.successMain };
  if (count < 6) return { label: "Strong", color: value.successDark };
};

// password strength indicator
export const strengthIndicator = (value) => {
  let strengths = 0;
  if (value.length > 5) strengths++;
  if (value.length > 7) strengths++;
  if (hasNumber(value)) strengths++;
  if (hasSpecial(value)) strengths++;
  if (hasMixed(value)) strengths++;
  return strengths;
};

/* Validator function */
export const validateString = (val) => {
  if (val && typeof val === "string" && val.length > 0) return true;
  else return false;
};

export const validateArrayData = (val) => {
  if (val && Array.isArray(val) && val.length > 0) return true;
  else return false;
};

export const validateObjectData = (val) => {
  if (val && typeof val === "object" && Object.keys(val).length > 0)
    return true;
  else return false;
};

export const sortArrayObjbyDate = (arr) => {
  return arr.sort((a, b) => new Date(b.x) - new Date(a.x));
}
