import React, { lazy } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

// project imports
import Initialize from "../views/Initialize";
import Loadable from "../components/Loadable";
import PrivateRoute from "../components/PrivateRoute";
import MainLayout from "../layouts/MainLayout";

// dashboard routing
const Dashboard = Loadable(
  lazy(() => import("../views/Dashboard/dashboard.page"))
);
const Advertisement = Loadable(
  lazy(() => import("../views/Advertisement/advertisement.page"))
);
const PriceTracking = Loadable(
  lazy(() => import("../views/PriceTracking/price-tracking.page"))
);
const Stockout = Loadable(
  lazy(() => import("../views/Stockout/stockout.page"))
);
const MarketShare = Loadable(
  lazy(() => import("../views/MarketShare/marketshare.page"))
);

const ReviewAnalysis = Loadable(
  lazy(() => import("../views/ReviewAnalysis/review-analysis.page"))
);
const Compare = Loadable(
  lazy(() => import("../views/ReviewAnalysis/Compare/compare.page"))
);
const SearchInsights = Loadable(
  lazy(() => import("../views/SearchInsights/search-insights.page"))
);
const WhiteSpace = Loadable(
  lazy(() => import("../views/WhiteSpace/whitespace.page"))
);
const ShareGrowth = Loadable(
  lazy(() => import("../views/ShareGrowth/share-growth.page"))
);

// import MarketShare from "../views/MarketShare/marketshare.page";
// import Stockout from "../views/Stockout/stockout.page";
// import Advertisement from "../views/Advertisement/advertisement.page";
// import PriceTracking from "../views/PriceTracking/price-tracking.page";
// import Dashboard from "../views/Dashboard/dashboard.page";
// import ReviewAnalysis from "../views/ReviewAnalysis/review-analysis.page";
// import Compare from "../views/ReviewAnalysis/Compare/compare.page";
// import SearchInsights from "../views/SearchInsights/search-insights.page";
// import WhiteSpace from "../views/WhiteSpace/whitespace.page";
// import ShareGrowth from "../views/ShareGrowth/share-growth.page";

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
  const location = useLocation();

  return (
    <Route
      path={[
        "/app/dashboard",
        "/app/price-tracking",
        "/app/advertisement",
        "/app/stockout",
        "/app/marketshare",
        "/app/review-analysis",
        "/app/review-analysis/compare",
        "/app/search-insights",
        "/app/whitespace",
        "/app/growth",
      ]}
    >
      <Initialize LayoutWrapperComponent={MainLayout}>
        <Switch location={location} key={location.pathname}>
          <PrivateRoute path="/app/dashboard" component={Dashboard} />
          <PrivateRoute path="/app/price-tracking" component={PriceTracking} />
          <PrivateRoute path="/app/advertisement" component={Advertisement} />
          <PrivateRoute path="/app/stockout" component={Stockout} />
          <PrivateRoute path="/app/marketshare" component={MarketShare} />
          <PrivateRoute
            path="/app/review-analysis/compare"
            component={Compare}
          />
          <PrivateRoute
            path="/app/review-analysis"
            component={ReviewAnalysis}
          />
          <PrivateRoute
            path="/app/search-insights"
            component={SearchInsights}
          />
          <PrivateRoute path="/app/whitespace" component={WhiteSpace} />
          <PrivateRoute path="/app/growth" component={ShareGrowth} />
        </Switch>
      </Initialize>
    </Route>
  );
};

export default MainRoutes;
