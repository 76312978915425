export default {
  // Auth endpoints
  login: "/api/v1/user/login/",
  signup: "/api/v1/user/signup/",
  logout: "/api/v1/user/logout/",
  profile: "/api/v1/profile/",
  forgotPassword: "/api/v1/user/forgot-password/",
  resetPassword: "/api/v1/user/reset-password/",

  // filter-api
  categoryBrandListFilterApi: "/api/v1/category-brand-list",

  attributeListApi: "/api/v1/attribute_listing",

  // Pricing
  pricingProductListApi: "/api/v1/pricing/product-list",
  pricingTrendsApi: "/api/v1/pricing/product-price-trends",

  // advertisement
  advertisementTypesList: "/api/v1/advertisement/types",
  advertisementBrandCount: "/api/v1/advertisement/brand-adv-count",
  advertisementKeyAds: "/api/v1/advertisement/key-ads-list",
  advertisementBrandTrends: "/api/v1/advertisement/brand-trends",
  advertisementSKUEventApi: "/api/v1/advertisement/sku_level_event",
  advertisementBrandEventApi: "/api/v1/advertisement/brand_level_event",

  // alertsApi
  alertsApi: "/api/v1/alerts",

  // marketShare api
  marketShareApi: "/api/v1/marketshare_revenue_table/report",
  marketSharePepsiSeries:
    "/api/v1/marketshare_manufacturer_revenue_table/report",

  // Dashboard
  dashboardInsights: "/api/v1/dashboard",

  // Revenue
  revenueTrendsSku: "/api/v1/revenue/revenue-trends-sku",
  revenueTrendsBrand: "/api/v1/revenue/revenue-trends-brands",

  // Review Analysis
  reviewCategoryBrandListFilterApi:
    "/api/v1/review-analysis/review_category_brand_list",
  reviewTimeSeries: "/api/v1/review-analysis/reviewcountbysentiment",
  reviewSentimentRating: "/api/v1/review-analysis/reviewsentimentrating",
  reviewSentimentComparison:
    "/api/v1/review-analysis/reviewsentimentcomparison",
  reviewProductList: "/api/v1/review-analysis/review_product_list",
  cardsApi: "/api/v1/card",
  reviewCountSeries: "/api/v1/review-analysis/review_series",
  attributesWordCloud: "/api/v1/review-analysis/review_world_cloud",
  wordCloudReviews: "/api/v1/review-analysis/review_world_cloud_by_keyword",
  wordCloudDownload: "/api/v1/review-analysis/review_world_cloud_download",

  // WhiteSpace
  whiteSpaceAttribList: "/api/v1/white-space/attribute-list",
  whiteSpaceReport: "/api/v1/white-space/report",

  // Search Insights
  searchKeywordsReportTable: "/api/v1/search_result_report",
  searchKeywordsList: "/api/v1/search_keyword_list",
  searchSovBrandsTrend: "/api/v1/sov_brand_trends",
  searchLowHighSovKeywords: "/api/v1/high_low_sov_keywords",
  searchAvgBrandsSov: "/api/v1/sov_score",
  sovBrandwiseReport: "/api/v1/sov_brandwise_report",
  sovKeywordwiseReport: "/api/v1/sov_keywordwise_report",
  sovKeywordsWeeklyTrend: "/api/v1/sov_keywordwise_weekly_trend",

  // GrowthApis
  growthRevenueSeriesApi: "/api/v1/growth_revenue",
  growthShareofGrowth: "/api/v1/share_of_growth_by_brand",
  fastestGrowingSku: "/api/v1/share_of_growth_by_sku",

  // Pricing Export
  exportPriceElasticity: "/api/v1/price_download",

  // Consolidated Api

  consolidatedApiMonthonMonthRevenue: "/api/v1/month_on_month_revenue",
};
