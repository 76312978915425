import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

import {
  SET_COUNTRY,
  SET_MENU,
  SET_RETAILER_LIST,
  SET_RETAILER,
} from "../../../../redux/app/actions";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Divider,
  List,
  Typography,
  Autocomplete,
  TextField,
  useMediaQuery,
} from "@material-ui/core";

// style constant
const useStyles = makeStyles((theme) => ({
  menuCaption: {
    ...theme.typography.menuCaption,
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption,
  },
  menuDivider: {
    marginTop: "2px",
    marginBottom: "10px",
  },
}));

const CountryDropDown = ({ t }) => {
  const classes = useStyles();
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleCountryChange = (countrySelected) => {
    dispatch({
      type: SET_COUNTRY,
      countrySelected,
    });

    matchDownMd && dispatch({ type: SET_MENU, opened: false });

    let retailersAccessList =
      appState.profileData["country_retailer_access"][appState.clientSelected][
        "retailer_access"
      ][countrySelected["country"]];

    dispatch({
      type: SET_RETAILER_LIST,
      retailersAccessList,
    });
    dispatch({
      type: SET_RETAILER,
      retailerSelected: retailersAccessList[0],
    });
  };

  return (
    <React.Fragment>
      <List
        subheader={
          <Typography
            variant="caption"
            className={classes.menuCaption}
            display="block"
            gutterBottom
          >
            {t("Country")}
          </Typography>
        }
      >
        <Autocomplete
          // disablePortal
          disableClearable={true}
          id="country"
          options={appState.countryAccessList ? appState.countryAccessList : []}
          value={appState.countrySelected || null}
          onChange={(event, newValue) => {
            console.log(newValue);
            handleCountryChange(newValue);
          }}
          getOptionLabel={(option) => option.country || ""}
          fullWidth
          size="small"
          renderInput={(params) => (
            <TextField {...params} color="secondary" placeholder="Search" />
          )}
        />
      </List>

      <Divider className={classes.menuDivider} />
    </React.Fragment>
  );
};

CountryDropDown.propTypes = {};

export default withTranslation()(CountryDropDown);
