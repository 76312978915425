import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import {
  SET_CLIENT,
  SET_FEATURE_LIST,
  SET_COUNTRY,
  SET_COUNTRY_LIST,
  SET_MENU,
  SET_RETAILER_LIST,
  SET_RETAILER,
} from "../../../../redux/app/actions";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Divider,
  List,
  Typography,
  Autocomplete,
  TextField,
  useMediaQuery,
} from "@material-ui/core";

// style constant
const useStyles = makeStyles((theme) => ({
  menuCaption: {
    ...theme.typography.menuCaption,
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption,
  },
  menuDivider: {
    marginTop: "2px",
    marginBottom: "10px",
  },
}));

const ClientDropDown = ({ history, t }) => {
  const classes = useStyles();
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleClientChange = (clientSelected) => {
    dispatch({
      type: SET_CLIENT,
      clientSelected,
    });

    matchDownMd && dispatch({ type: SET_MENU, opened: false });

    // Set feature list

    let featuresAccessList =
      appState.profileData["features_access_list"][clientSelected];

    dispatch({
      type: SET_FEATURE_LIST,
      featuresAccessList,
    });

    // Set Country Data
    let countryAccessList = Object.values(
      appState.profileData["country_retailer_access"][clientSelected][
        "countries_access"
      ]
    );
    dispatch({
      type: SET_COUNTRY_LIST,
      countryAccessList,
    });

    let countrySelected = countryAccessList[0];
    dispatch({
      type: SET_COUNTRY,
      countrySelected,
    });

    let retailersAccessList =
      appState.profileData["country_retailer_access"][clientSelected][
        "retailer_access"
      ][countrySelected["country"]];

    dispatch({
      type: SET_RETAILER_LIST,
      retailersAccessList,
    });
    dispatch({
      type: SET_RETAILER,
      retailerSelected: retailersAccessList[0],
    });
  };

  return (
    <React.Fragment>
      <List
        subheader={
          <Typography
            variant="caption"
            className={classes.menuCaption}
            display="block"
            gutterBottom
          >
            {t("Client")}(Admin)*
          </Typography>
        }
      >
        <Autocomplete
          // disablePortal
          disableClearable={true}
          id="client"
          options={appState.clientsAccessList ? appState.clientsAccessList : []}
          value={appState.clientSelected || null}
          onChange={(event, newValue) => {
            console.log(newValue);
            handleClientChange(newValue);
          }}
          fullWidth
          getOptionLabel={(option) => option || ""}
          size="small"
          renderInput={(params) => (
            <TextField {...params} color="secondary" placeholder="Search" />
          )}
        />
      </List>

      <Divider className={classes.menuDivider} />
    </React.Fragment>
  );
};

ClientDropDown.propTypes = {};

export default withTranslation()(withRouter(ClientDropDown));
