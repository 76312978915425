import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Chip,
  List,
  Typography,
  TextField,
  Autocomplete,
  Divider,
  Box,
  Grid,
  Popover,
} from "@material-ui/core";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import { validateArrayData } from "../../../../utils";

// assets
import { IconBell } from "@tabler/icons";
import { RequestAlertsEndpoint } from "../../../../components/AxiosEndpoints";
import NotificationList from "./NotificationList";
import CustomCardComponent from "../../../../components/CustomCardComponent";

const useStyles = makeStyles((theme) => ({
  alertChip: {
    height: "40px",
    alignItems: "center",
    borderRadius: "6px",
    transition: "all .2s ease-in-out",
    borderColor: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.light,
    '&[aria-controls="menu-list-grow"], &:hover': {
      borderColor: theme.palette.secondary.main,
      background: theme.palette.secondary.main + "!important",
      color: theme.palette.secondary.light,
      "& svg": {
        stroke: theme.palette.secondary.light,
      },
    },
  },
  alertLabel: {
    lineHeight: 0,
    padding: "10px 8px",
  },
  listItem: {
    marginTop: "5px",
  },
  alertTypeBadge: {
    fontSize: "12px",
    height: "20px",
    "& .MuiChip-label": {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    "&.MuiChip-filledPrimary": {
      backgroundColor: "#cee5f7",
      color: "#0077d6",
    },
    "&.MuiChip-filledSecondary": {
      backgroundColor: "#ebe0ff",
      color: "#673ab7",
    },
    "&.MuiChip-filledSuccess": {
      backgroundColor: theme.palette.success.light,
      color: "#057935",
    },
  },
  alertTypeIcon: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
  },
}));

const NotificationSection = ({ t }) => {
  const classes = useStyles();
  const theme = useTheme();
  const appState = useSelector((state) => state.app);

  // Popover Event
  const [anchorPopoverEl, setAnchorPopoverEl] = React.useState(null);

  const handlePopoverClick = (event) => {
    setAnchorPopoverEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorPopoverEl(null);
  };

  const openPopover = Boolean(anchorPopoverEl);
  const popoverID = openPopover ? "simple-popover" : undefined;

  const { retailerSelected, category } = appState;
  const alertTypes = [
    "All",
    "No Stock",
    "Low Stock",
    "Price Change",
    "Highest Sale Surge",
    "Sale Surge",
  ];
  const initialState = {
    activeAlertType: alertTypes[0],
    isAlertLoading: false,
    alertsData: [],
  };

  const [state, setState] = useState(initialState);

  const handleStateReset = () => {
    setState(initialState);
  };

  // Handle Alert Type Change
  const handleAlertTypeChange = (value) => {
    setState((prev) => ({
      ...prev,
      activeAlertType: value,
    }));

    if (value === "All") {
      let queryObject = {
        retailer: retailerSelected,
        start_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        category,
        alert_type: alertTypes,
      };
      handleFetchAlertsData(queryObject);
    }
  };

  // Fetch Alerts Data
  const handleFetchAlertsData = (payload) => {
    setState((prev) => ({
      ...prev,
      isAlertLoading: true,
      alertsData: [],
    }));

    RequestAlertsEndpoint(payload)
      .then((res) => {
        if (res.status === 200) {
          setState((prev) => ({
            ...prev,
            isAlertLoading: false,
            alertsData: res.data && res.data.length ? res.data : [],
          }));
        } else {
          setState((prev) => ({
            ...prev,
            isAlertLoading: false,
            alertsData: [],
          }));
        }
      })
      .catch((error) => {
        console.log("handleFetchAlertsData****", error.response);
        setState((prev) => ({
          ...prev,
          isAlertLoading: false,
          alertsData: [],
        }));
      });
  };

  // Filter Notification Data
  const handleFilterNotifications = (data, alertType) => {
    return data.filter((item) =>
      alertType != "All" ? item.alert_type === alertType : item
    );
  };

  const filterNotificationData = handleFilterNotifications(
    state.alertsData,
    state.activeAlertType
  );

  useEffect(() => {
    if (retailerSelected && category) {
      let queryObject = {
        retailer: retailerSelected,
        start_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        category,
        alert_type: alertTypes,
      };
      handleFetchAlertsData(queryObject);
    } else {
      handleStateReset();
    }

    return () => {
      handleStateReset();
    };
  }, [retailerSelected, category]);

  return (
    <>
      <Chip
        aria-describedby={popoverID}
        classes={{ label: classes.alertLabel }}
        className={classes.alertChip}
        label={
          <IconBell
            stroke={1.5}
            size="1.3rem"
            color={theme.palette.secondary.main}
          />
        }
        aria-controls={openPopover ? "menu-list-grow" : undefined}
        variant="outlined"
        onClick={handlePopoverClick}
        sx={{ marginRight: "10px" }}
      />

      <Popover
        id={popoverID}
        open={openPopover}
        anchorEl={anchorPopoverEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: { width: "700px", marginTop: "10px" },
        }}
      >
        <Box sx={{ padding: "15px 20px" }}>
          <Typography
            varient="h4"
            sx={{ marginBottom: "8px", fontWeight: "500", color: "#333333" }}
          >
            {t("Notifications")}
          </Typography>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            spacing={1}
          >
            <Grid item xs={4}>
              <Autocomplete
                disablePortal
                disableClearable={true}
                id="alert_type"
                options={alertTypes && alertTypes.length ? alertTypes : []}
                value={state.activeAlertType || null}
                onChange={(event, newValue) => {
                  handleAlertTypeChange(newValue);
                }}
                fullWidth
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color="secondary"
                    variant="outlined"
                    placeholder="Select Alert type"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Typography sx={{ fontWeight: 500, color: "#2196f3" }}>
                Total Notifications: {filterNotificationData.length}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <CustomCardComponent
          isCard={false}
          contentHeight={360}
          isLoadingSpin={state.isAlertLoading}
        >
          {validateArrayData(filterNotificationData) ? (
            <PerfectScrollbar style={{ height: "360px" }}>
              <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                {filterNotificationData.map((value, index) => (
                  <NotificationList key={index} data={value} />
                ))}
              </List>
            </PerfectScrollbar>
          ) : null}
        </CustomCardComponent>
      </Popover>
    </>
  );
};

export default withTranslation()(withRouter(NotificationSection));
