import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

// material-ui
import { makeStyles, withStyles } from "@material-ui/styles";
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";

// imports
import MainCard from "../MainCard";
import Transitions from "../Transitions";

import InfoIcon from "@material-ui/icons/InfoOutlined";

// Tooltip styles
const useStylesBootstrap = makeStyles((theme) => ({
  tooltip: {
    //   backgroundColor: theme.palette.common.black,
    fontSize: 16,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const CustomCardComponent = React.forwardRef(
  (
    {
      cardTitle,
      isTitleLocalize,
      showInfoIcon,
      infoIconContent,
      cardAction,
      contentHeight,
      isLoadingSpin,
      children,
      isCard,
      showNoDataText,
      t,
    },
    ref
  ) => {
    return (
      <>
        {isCard ? (
          <Transitions in={true}>
            <MainCard ref={ref} content={true}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="h4">
                        {isTitleLocalize ? t(cardTitle) : cardTitle}
                      </Typography>
                    </Grid>
                    <Grid item display="flex" alignItems="center">
                      {cardAction}
                      {showInfoIcon && (
                        <BootstrapTooltip
                          title={infoIconContent}
                          sx={{ marginLeft: 1 }}
                        >
                          <InfoIcon />
                        </BootstrapTooltip>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box height={contentHeight} width="100%">
                    {/* Loading Spin */}
                    {isLoadingSpin && (
                      <Box
                        display="flex"
                        height="100%"
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress color="primary" />
                      </Box>
                    )}
                    {/* No Content Text */}
                    {!children && !isLoadingSpin && showNoDataText && (
                      <Typography
                        variant={"h5"}
                        textAlign="center"
                        color="inherit"
                      >
                        {t("NoDataText")}
                      </Typography>
                    )}
                    {/* card Content  */}
                    {!isLoadingSpin && children ? children : null}
                  </Box>
                </Grid>
              </Grid>
            </MainCard>
          </Transitions>
        ) : (
          <Box height={contentHeight} width="100%">
            {/* Loading Spin */}
            {isLoadingSpin && (
              <Box
                display="flex"
                height="100%"
                width="100%"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress color="primary" />
              </Box>
            )}
            {/* No Content Text */}
            {!children && !isLoadingSpin && showNoDataText && (
              <Typography
                variant={"h5"}
                textAlign="center"
                color="inherit"
                sx={{ paddingTop: "10px" }}
              >
                {t("NoDataText")}
              </Typography>
            )}
            {/* card Content  */}
            {!isLoadingSpin && children ? children : null}
          </Box>
        )}
      </>
    );
  }
);

CustomCardComponent.propTypes = {
  isCard: PropTypes.bool,
  showNoDataText: PropTypes.bool,
  showInfoIcon: PropTypes.bool,
  infoIconContent: PropTypes.string,
  cardTitle: PropTypes.string,
  isTitleLocalize: PropTypes.bool,
  contentHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isLoadingSpin: PropTypes.bool.isRequired,
  children: PropTypes.node,
  cardAction: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
};

CustomCardComponent.defaultProps = {
  isTitleLocalize: false,
  isCard: true,
  showNoDataText: true,
  showInfoIcon: true,
  infoIconContent: "Sample Text",
};

export default withTranslation()(CustomCardComponent);
