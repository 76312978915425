// action - state management
import * as actionTypes from "./actions";
import moment from "moment";

// Sample for Profile Response
// {
//   "profileData": {
//       "user_name": "Gokul",
//       "user_email": "gokulkrishnan@ongil.io",
//       "user_country": "india",
//       "user_type": "client",
//       "clients": {
//           "Pepsi": 3,
//           "3M": 4
//       },
//       "features_access_list": {
//           "Pepsi": [
//               "market_share",
//               "price_tracking",
//               "advertisement",
//               "stock_out"
//           ],
//           "3M": [
//               "market_share",
//               "advertisement",
//               "stock_out"
//           ]
//       },
//       "country_retailer_access": {
//           "Pepsi": {
//               "countries_access": {
//                   "Singapore": {
//                       "country": "Singapore",
//                       "currency": "SGD"
//                   },
//                   "Korea": {
//                       "country": "Korea",
//                       "currency": "KPW"
//                   }
//               },
//               "retailer_access": {
//                   "Singapore": [
//                       "FairPrice",
//                       "RedMart",
//                       "PandaMart"
//                   ],
//                   "Korea": [
//                       "Coupang"
//                   ]
//               }
//           },
//           "3M": {
//               "countries_access": {
//                   "Singapore": {
//                       "country": "Singapore",
//                       "currency": "SGD"
//                   },
//                   "Korea": {
//                       "country": "Korea",
//                       "currency": "KPW"
//                   }
//               },
//               "retailer_access": {
//                   "Singapore": [
//                       "FairPrice",
//                       "RedMart",
//                       "PandaMart"
//                   ],
//                   "Korea": [
//                       "Coupang"
//                   ]
//               }
//           }
//       }
//   }
// }

const LanguageList = [
  { language: "English", language_code: "en" },
  { language: "Korean", language_code: "ko" },
  { language: "Japanese", language_code: "ja" },
];

export const initialState = {
  isOpen: [], //for active default menu
  opened: true,
  appLanguage: LanguageList[0],
  LanguageList,
  // ** Initialize **
  isMainLoading: true,
  isLoadingSpin: true,
  // clientAccess
  clientSelected: "",
  clientsAccessList: [],
  // feature access
  featuresAccessList: [],
  // countryAccess
  countrySelected: {},
  countryAccessList: [],
  // retailerAccess
  retailersAccessList: [],
  retailerSelected: "",
  start_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD"),
  category: "",
  categoryAccessList: [],
  profileData: {},
};

//-----------------------|| CUSTOMIZATION REDUCER ||-----------------------//

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      const id = action.id;
      return {
        ...state,
        isOpen: [id],
      };
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.opened,
      };
    case actionTypes.SET_APP_LANGUAGE:
      return {
        ...state,
        appLanguage: action.appLanguage,
      };

    // action - app Initialize
    case actionTypes.SET_IS_MAIN_LOADING:
      return {
        ...state,
        isMainLoading: action.isMainLoading,
      };

    case actionTypes.SET_IS_LOADING_SPIN:
      return {
        ...state,
        isLoadingSpin: action.isLoadingSpin,
      };

    case actionTypes.SET_USER_PROFILE:
      return {
        ...state,
        profileData: action.profileData,
      };

    // feature access

    case actionTypes.SET_FEATURE_LIST:
      return {
        ...state,
        featuresAccessList: action.featuresAccessList,
      };

    // clientAccess
    case actionTypes.SET_CLIENT:
      return {
        ...state,
        clientSelected: action.clientSelected,
      };

    case actionTypes.SET_CLIENT_LIST:
      return {
        ...state,
        clientsAccessList: action.clientsAccessList,
      };

    // countryAccess
    case actionTypes.SET_COUNTRY:
      return {
        ...state,
        countrySelected: action.countrySelected,
      };

    case actionTypes.SET_COUNTRY_LIST:
      return {
        ...state,
        countryAccessList: action.countryAccessList,
      };

    // retailerAccess
    case actionTypes.SET_RETAILER_LIST:
      return {
        ...state,
        retailersAccessList: action.retailersAccessList,
      };
    case actionTypes.SET_RETAILER:
      return {
        ...state,
        retailerSelected: action.retailerSelected,
      };

    case actionTypes.SET_START_DATE:
      return {
        ...state,
        start_date: action.start_date,
      };
    case actionTypes.SET_END_DATE:
      return {
        ...state,
        end_date: action.end_date,
      };
    case actionTypes.SET_CATEGORY:
      return {
        ...state,
        category: action.category,
      };
    case actionTypes.SET_CATEGORY_LIST:
      return {
        ...state,
        categoryAccessList: action.categoryAccessList,
      };
    default:
      return state;
  }
};

export default appReducer;
