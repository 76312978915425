export const colors = {
  // paper & background
  paper: "#ffffff",

  // // primary
  // primaryLight: "#E3E8E8",
  // primaryMain: "#1B3E43",
  // primaryDark: "#18383D",
  // primary200: "#8B9FA1",
  // primary800: "#10282C",

  // // secondary
  // secondaryLight: "#E8F6F5",
  // secondaryMain: "#62BBB8",
  // secondaryDark: "#59ABA5",
  // secondary200: "#9FD8D6",
  // secondary800: "#4F9892",

  // // primary
  primaryLight: "#e3f2fd",
  primaryMain: "#2196f3",
  primaryDark: "#1e88e5",
  primary200: "#90caf9",
  primary800: "#1565c0",

  // secondary
  secondaryLight: "#ede7f6",
  secondaryMain: "#673ab7",
  secondaryDark: "#5e35b1",
  secondary200: "#b39ddb",
  secondary800: "#4527a0",

  // success Colors
  successLight: "#b9f6ca",
  success200: "#69f0ae",
  successMain: "#00e676",
  successDark: "#00c853",

  // error
  errorLight: "#ef9a9a",
  errorMain: "#f44336",
  errorDark: "#c62828",

  // orange
  orangeLight: "#fbe9e7",
  orangeMain: "#ffab91",
  orangeDark: "#d84315",

  // warning
  warningLight: "#fff8e1",
  warningMain: "#ffe57f",
  warningDark: "#ffc107",

  // grey
  grey50: "#fafafa",
  grey100: "#f5f5f5",
  grey200: "#eeeeee",
  grey300: "#e0e0e0",
  grey500: "#9e9e9e",
  grey600: "#757575",
  grey700: "#616161",
  grey900: "#212121",
};
