import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";

// Import
import {
  RequestProfileEndpoint,
  RequestCategoryBrandEndpoint,
  handleAuthorizationError,
} from "../../components/AxiosEndpoints";
import { validateArrayData, validateObjectData } from "../../utils";
import {
  SET_IS_MAIN_LOADING,
  SET_IS_LOADING_SPIN,
  SET_USER_PROFILE,
  SET_CLIENT,
  SET_CLIENT_LIST,
  SET_FEATURE_LIST,
  SET_COUNTRY,
  SET_COUNTRY_LIST,
  SET_RETAILER_LIST,
  SET_RETAILER,
  SET_CATEGORY_LIST,
  SET_CATEGORY,
} from "../../redux/app/actions";
import MainLayout from "../../layouts/MainLayout";

// Material UI
import { makeStyles } from "@material-ui/styles";
import { CircularProgress, Backdrop } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: "white",
  },
}));

const Initialize = ({ LayoutWrapperComponent, history, children }) => {
  const classes = useStyles();
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const handleCategoryLoad = (queryObject) => {
    RequestCategoryBrandEndpoint(queryObject)
      .then((res) => {
        if (res.status === 200 && validateObjectData(res.data)) {
          dispatch({
            type: SET_CATEGORY_LIST,
            categoryAccessList: res.data["category"],
          });

          dispatch({
            type: SET_CATEGORY,
            category: res.data["category"][0],
          });
          dispatch({
            type: SET_IS_MAIN_LOADING,
            isMainLoading: false,
          });
          dispatch({
            type: SET_IS_LOADING_SPIN,
            isLoadingSpin: false,
          });
        } else {
          dispatch({
            type: SET_IS_MAIN_LOADING,
            isMainLoading: false,
          });
          dispatch({
            type: SET_IS_LOADING_SPIN,
            isLoadingSpin: false,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        dispatch({
          type: SET_IS_MAIN_LOADING,
          isMainLoading: false,
        });
        dispatch({
          type: SET_IS_LOADING_SPIN,
          isLoadingSpin: false,
        });
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      RequestProfileEndpoint()
        .then((profileResponse) => {
          if (
            profileResponse.status === 200 &&
            validateObjectData(profileResponse.data)
          ) {
            // Set Profile Data
            let profileData = profileResponse.data["profileData"];
            dispatch({
              type: SET_USER_PROFILE,
              profileData,
            });

            // Set Client Data
            let clientsAccessList = Object.keys(profileData["clients"]);

            dispatch({
              type: SET_CLIENT_LIST,
              clientsAccessList,
            });

            let clientSelected = clientsAccessList[0];

            dispatch({
              type: SET_CLIENT,
              clientSelected,
            });

            // Set feature list

            let featuresAccessList =
              profileData["features_access_list"][clientSelected];

            dispatch({
              type: SET_FEATURE_LIST,
              featuresAccessList,
            });

            // Set Country Data
            let countryAccessList = Object.values(
              profileData["country_retailer_access"][clientSelected][
                "countries_access"
              ]
            );

            dispatch({
              type: SET_COUNTRY_LIST,
              countryAccessList,
            });

            let countrySelected = countryAccessList[0];

            dispatch({
              type: SET_COUNTRY,
              countrySelected,
            });

            // Set Retailer Data
            let retailersAccessList =
              profileData["country_retailer_access"][clientSelected][
                "retailer_access"
              ][countrySelected["country"]].reverse();

            dispatch({
              type: SET_RETAILER_LIST,
              retailersAccessList,
            });
            dispatch({
              type: SET_RETAILER,
              retailerSelected: retailersAccessList[0],
            });

            handleCategoryLoad({
              retailer: retailersAccessList[0],
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
          dispatch({
            type: SET_IS_MAIN_LOADING,
            isMainLoading: false,
          });
          localStorage.removeItem("token");
          history.push("/login");
        });
    } else {
      dispatch({
        type: SET_IS_MAIN_LOADING,
        isMainLoading: false,
      });
      localStorage.removeItem("token");
      history.push("/login");
    }

    return () => {
      dispatch({
        type: SET_IS_MAIN_LOADING,
        isMainLoading: true,
      });
    };
  }, []);

  return (
    <>
      {!localStorage.getItem("token") && (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      )}
      <Backdrop className={classes.backdrop} open={appState.isLoadingSpin}>
        <CircularProgress color="primary" />
      </Backdrop>
      {!appState.isMainLoading && (
        <LayoutWrapperComponent>{children}</LayoutWrapperComponent>
      )}
    </>
  );
};

Initialize.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withRouter(Initialize);
